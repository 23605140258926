import theme from '../../theme';

const styles = {
  sessionButton: {
    background: '#F9B234',
    color: '#074F71',
    borderRadius: '34px',
    padding: '0px 10px',
    fontSize: '16px',
    width: '200px',
    height: '41px',
    textTransform: `capitalize`,
    fontWeight: `bold`,
    '&:hover': {
      background: '#074F71',
      color: `#FAB234`,
      border: '1px solid #FAB234',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      height: '35px',
    },
  },
};

export default styles;
