import theme from '../../theme';

const drawerWidth = 240;

const styles = {
  row: {
    flexGrow: 1,
  },
  toolbarSection: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    padding: `0 6% 0`,
    [theme.breakpoints.up('xl')]: {
      padding: '0',
    },
  },
  toolbar: {
    display: 'flex',
    height: '72px',
    justifyContent: 'space-between',
    columnGap: '5px',
  },
  AppBar: {
    height: '72px',
    backgroundColor: '#074F71',
    backgroundSize: 'cover',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  avatar: {
    width: '160px',
    borderRadius: 0,
    marginTop: '10px',
    [theme.breakpoints.down(500)]: {
      marginTop: 0,
      width: '140px',
      height: '35px',
    },
  },
  menuButon: {
    color: '#fff',
    display: 'flex',
    [theme.breakpoints.up(900)]: {
      display: `none`,
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  secondarymenu: {
    position: `absolute`,
    left: 0,
    right: 0,
    display: `flex`,
    height: `auto`,
    fontSize: `14px`,
    width: `100%`,
    flexWrap: `nowrap`,
    alignItems: `center`,
    justifyContent: `space-between`,
    padding: `0 0.55rem`,
    transition: `height 0.3s`,
    userSelect: `none`,
    overflowX: `scroll`,
    overflowY: `hidden`,
    background: `#074F71`,
    [theme.breakpoints.up(900)]: {
      display: `none`,
    },
  },
  linksContainer: {
    flexGrow: 1,
    alignItems: `center`,
    justifyContent: `space-between`,
  },
};

export default styles;
