import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, Container, Grid, Toolbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Link } from 'gatsby';
import { useState } from 'react';
import logo from '../../images/logo_navbar.svg';
import { StyledDiv } from '../Styled';
import styles from './Navbar.styles';
import NavbarLinks from './NavbarLinks';
import NavItemMobile from './NavItemMobile';
import SessionButton from './SessionButton';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    open?: boolean;
  }
}

const NavBar = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <StyledDiv sx={styles.row}>
      <AppBar position="fixed" color="default" sx={styles.AppBar}>
        <Container sx={styles.container} maxWidth="lg" component="div" disableGutters>
          <Toolbar sx={styles.toolbar} disableGutters>
            <Grid sx={styles.toolbarSection}>
              <Avatar
                src={logo}
                alt="Logo"
                to="/"
                component={Link}
                sx={styles.avatar}
                sizes="cover"
              />
            </Grid>

            <Grid sx={[styles.toolbarSection, styles.linksContainer]}>
              <NavbarLinks />
            </Grid>

            <Grid sx={styles.toolbarSection}>
              <SessionButton />
            </Grid>

            <IconButton
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={styles.menuButon}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
        {open && (
          <Grid>
            <Grid sx={styles.secondarymenu}>
              <NavItemMobile setOpen={setOpen} />
            </Grid>
          </Grid>
        )}
      </AppBar>
    </StyledDiv>
  );
};

export default NavBar;
