import { navigate } from 'gatsby';
import React from 'react';
import { AuthContext, User } from '../components/AuthProvider';
import { checkUser } from '../utils/authUtils';

export interface IUseAuth {
  handleSessionToken: (accessToken: string) => Promise<void>;
  logout: () => void;
  isAuthenticated: () => boolean;
  bearerToken: string | null;
  user: User | null;
}

export function useAuth(): IUseAuth {
  const { user, bearerToken, removeUser, createUserFromToken } =
    React.useContext<AuthContext>(AuthContext);

  async function handleSessionToken(accessToken: string) {
    const user = createUserFromToken(accessToken);
    if (!user) {
      return navigate('/emailvalidated/');
    }
    if (user.completedProfile) {
      const memoryLink = localStorage.getItem('link');
      localStorage.setItem('link', '');
      if (memoryLink == 'referral') {
        window.location.replace(
          `${process.env.GATSBY_SITE_URL}/external-login/index?token=${accessToken}&view=referral`
        );
      } else {
        window.location.replace(
          `${process.env.GATSBY_SITE_URL}/external-login/index?token=${accessToken}`
        );
      }
    } else {
      navigate('/signup-form/');
    }
  }

  function logout() {
    removeUser();
    navigate('/');
  }

  function isAuthenticated() {
    return checkUser(user);
  }

  return {
    user,
    isAuthenticated,
    handleSessionToken,
    logout,
    bearerToken,
  };
}
