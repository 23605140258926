import { Button } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import styles from './SessionButton.styles';
import { navigate } from 'gatsby';

const SessionButton = () => {
  const { user, logout } = useAuth();

  return (
    <Button
      id="ButtonStart"
      color="inherit"
      sx={styles.sessionButton}
      onClick={() => {
        console.log(
          'window.location.pathname.includes(signup-form)',
          window.location.pathname.includes('signup-form')
        );
        if (user && !user.completedProfile && !window.location.pathname.includes('signup-form')) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'buttonClick',
            category: 'NavBar',
            action: 'click_continuar_registro',
            label: 'Barra de navegación, click en Continuar Registro',
          });
          navigate('/signup-form');
        } else if (user && window.location.pathname.includes('signup-form')) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'buttonClick',
            category: 'NavBar',
            action: 'click_cerrar_sesion',
            label: 'Barra de navegación, click en Cerrar Sesión',
          });
          logout();
        } else {
          window.location.href = `${process.env.GATSBY_URL_NEW_FRONT}`;
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'buttonClick',
            category: 'NavBar',
            action: 'click_ingresar',
            label: 'Barra de navegación, click en Ingresar',
          });
        }
      }}
    >
      {user &&
        !user.completedProfile &&
        !window.location.pathname.includes('signup-form') &&
        'Continuar Registro'}
      {user && window.location.pathname.includes('signup-form') && 'Cerrar Sesión'}
      {!user && 'Ingresar'}
    </Button>
  );
};

export default SessionButton;
