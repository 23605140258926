import { navigate } from 'gatsby';
import jwt_decode from 'jwt-decode';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { checkUser, getTokenFromStorage } from '../utils/authUtils'; // minutes

export type User = {
  uuid: string;
  email: string;
  flags: number;
  completedProfile: boolean;
  exp: number;
};

export type BearerTokenInfo = {
  uuid: string;
  email: string;
  flags: number;
  exp: number;
  iat: number;
};

export type AuthData = {
  accessToken: string;
};

export interface AuthContext {
  user: User | null;
  removeUser: () => void;
  bearerToken: string | null;
  createUserFromToken: (accessToken: string) => User | null;
}

export const AuthContext = createContext<AuthContext>({} as AuthContext);

export interface IAuthProvider {
  children: React.ReactNode;
}

const isLocation = (location: unknown): location is Location => {
  if (!(location as Location).pathname) return false;
  if (!(location as Location).search) return false;
  return true;
};

const isPageLoadEvent = (
  e: Event
): e is CustomEvent<{ location: Location; prevLocation: Location }> => {
  if (!(e as CustomEvent<{ location: Location; prevLocation: Location }>).detail) return false;
  if (!(e as CustomEvent<{ location: Location; prevLocation: Location }>).detail.location)
    return false;
  if (!(e as CustomEvent<{ location: Location; prevLocation: Location }>).detail.prevLocation)
    return false;
  if (
    !isLocation((e as CustomEvent<{ location: Location; prevLocation: Location }>).detail.location)
  )
    return false;
  if (
    !isLocation(
      (e as CustomEvent<{ location: Location; prevLocation: Location }>).detail.prevLocation
    )
  )
    return false;
  return true;
};

export function AuthProvider({ children }: IAuthProvider) {
  const [user, setUser] = useState<User | null>(null);
  const [bearerToken, setBearerToken] = useState<string | null>(null);

  const removeUser = useCallback(() => {
    setUser(null);
    setBearerToken(null);
    localStorage.removeItem('bearer');
  }, [setUser, setBearerToken]);

  const createUserFromToken = (accessToken: string): User | null => {
    try {
      const bearerTokenInfo = jwt_decode<BearerTokenInfo>(accessToken);
      const userInfo = {
        uuid: bearerTokenInfo.uuid,
        email: bearerTokenInfo.email,
        flags: bearerTokenInfo.flags,
        completedProfile: bearerTokenInfo.flags === 1,
        exp: bearerTokenInfo.exp,
      };
      setUser(userInfo);
      localStorage.setItem('bearer', JSON.stringify({ accessToken }));
      setBearerToken(`Bearer ${accessToken}`);
      return userInfo;
    } catch {
      return null;
    }
  };

  // check if the user is still valid on page load
  useEffect(() => {
    const accessToken = getTokenFromStorage();
    if (!accessToken) {
      removeUser();
      return;
    }
    const bearerTokenInfo = jwt_decode<BearerTokenInfo>(accessToken);
    const loadedUser = {
      uuid: bearerTokenInfo.uuid,
      email: bearerTokenInfo.email,
      flags: bearerTokenInfo.flags,
      completedProfile: bearerTokenInfo.flags === 1,
      exp: bearerTokenInfo.exp,
    };
    if (checkUser(loadedUser)) {
      setBearerToken(`Bearer ${accessToken}`);
      setUser(loadedUser);
    } else {
      removeUser();
    }
  }, []);

  // periodically check if the user is still valid (every minute)
  useEffect(() => {
    const interval = setInterval(() => {
      if (user && !checkUser(user)) {
        removeUser();
        navigate('/');
      }
      const accessToken = getTokenFromStorage();
      if (user && !accessToken) {
        removeUser();
        navigate('/');
        return;
      }
    }, 1000 * 60); // check every minute
    return () => clearInterval(interval);
  }, [user, removeUser]);

  // check if the user is still valid on page load
  useEffect(() => {
    const handlePageLoad = (e: Event) => {
      if (!isPageLoadEvent(e)) return;
      if (user && !checkUser(user)) {
        removeUser();
        if (e.detail.location.pathname !== '/') navigate('/');
        return;
      }
      const accessToken = getTokenFromStorage();
      if (user && !accessToken) {
        removeUser();
        if (e.detail.location.pathname !== '/') navigate('/');
        return;
      }
    };
    window.addEventListener('onPageLoad', handlePageLoad);
    return () => {
      window.removeEventListener('onPageLoad', handlePageLoad);
    };
  }, []);

  const value = {
    user,
    removeUser,
    bearerToken,
    createUserFromToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
