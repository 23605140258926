import { AuthData, User } from '../components/AuthProvider';

export const TOKEN_TIME_TO_LIVE = 60; // minutes

export const getTokenFromStorage = () => {
  if (typeof window === 'undefined') return null;
  const rawAuthData = localStorage.getItem('bearer');
  const authData: AuthData | null = rawAuthData ? JSON.parse(rawAuthData) : null;
  return authData ? authData.accessToken : null;
};

export const checkUser = (user: User | null) => {
  // TODO: check with the server
  if (!user) return false;
  const delta = user.exp * 1000 - Date.now();

  if (delta < 0 || delta > TOKEN_TIME_TO_LIVE * 60 * 1000) {
    return false;
  }
  return true;
};
