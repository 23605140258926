/* global window, CustomEvent*/

import React from 'react';
import { Location } from '@reach/router';
import TopLayout from './TopLayout';

export const wrapRootElement = ({ element, props }) => {
  return (
    <Location>
      {({ location }) => (
        <TopLayout location={location} {...props}>
          {element}
        </TopLayout>
      )}
    </Location>
  );
};

export const wrapPageElement = ({ element }) => {
  return <div>{element}</div>;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const event = new CustomEvent('onPageLoad', {
    detail: {
      location,
      prevLocation,
    },
  });
  window.dispatchEvent(event);
};
